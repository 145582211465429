<template>
  <div class="about max-width">
    <div>
      <h1>Misc.</h1>
      <p>Things I've made that are still alive on the web:</p>
      <p><a href="https://drinkfieldofdreams.com/pages/who-is-my-bottle" target="_blank">Player Series Bottle Lookup</a> for Field of Dreams Whiskey</p>
      <p><a href="https://kit.warroad.com/" target="_blank">Team Kit Builder</a> for Warroad</p>
      <p><a href="https://client-cgrzlhwaka-uk.a.run.app/" target="_blank">OAuth</a> and <a href="https://node-api-key-cgrzlhwaka-uk.a.run.app/" target="_blank">JWT</a> API Demo Apps for Triple Whale</p>
      <p><a href="https://github.com/Triple-Whale/triple-whale-public-apis/tree/master/examples/node#-triple-whale-api-node-app" target="_blank">Public API Documentation</a> for Triple Whale</p>
      <p><a href="https://tw-dev-docs.netlify.app/" target="_blank">Full Stack Developer Documentation</a> for Triple Whale</p>
      <p><a href="https://hello.shop-tetra.com/" target="_blank">Ad-Safe Landing page</a> for Tetra</p>
      <p>Shopify <a href="https://westinghouse.netlify.app/" target="_blank">Theme Documentation</a> for Westinghouse</p>
      <p><a href="https://claritysallyday.com/">Clarity by Sally Day</a> for Marquee Creative</p>
      <p><a href="https://tw-arima.netlify.app/" target="_blank">Serverless AutoRegressive Integrated Moving Average</a> (<a href="https://en.wikipedia.org/wiki/Autoregressive_integrated_moving_average" target="_blank">ARIMA</a>)</p>
      <p><a href="https://edgelord.netlify.app/" target="_blank">Headless Shopify Store</a> for EdgeLord</p>
      <p><a href="https://scrum-principles.netlify.app/" target="_blank">Scrum Principles</a> documentation site</p>
      <p>3D Model <a href="https://astro-stl-viewer.netlify.app/" target="_blank">STL Viewer</a></p>
      <p><a href="https://astro-retro.netlify.app/" target="_blank">Shopify SSR Demo</a> with <a href="https://astro.build" target="_blank">Astro</a></p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'About'
}
</script>

<style lang="scss" scoped>
.about {
  > div {
    &:last-of-type {
      margin-top: 2em;
    }
  }

  /deep/ img {
    width: 100%;
  }
}
</style>
